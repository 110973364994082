<template>
  <!--  Search Console of Google -->
  <meta name="google-site-verification" content="h5qMz_eEUa2mYQa9LYN6OdN_-a-hfIxTf-BoanlIw6M" />
  
  

  <div id="sorounding">
  
    <div id="content">

      <div id="banner">
        
          <div>
          
            <router-link to="/">
                <div id="icon"><img src="./assets/Icon180.png" /></div>
            </router-link>
            <router-link to="/"><div>Die App</div></router-link>
            <!-- <router-link to="/contact"><div>Kontakt</div></router-link> -->
            <router-link to="/kurz"><div>KURZ</div></router-link>
            <!--  <div>Kontakt</div> -->
            <router-link to="/android"><div>Android Version</div></router-link>
          </div>
          
          <img id="banner_image" src="./assets/banner.png" />

      </div>

      <main>
        <router-view></router-view>
      </main>

      <About></About>
      
  </div>

</div>


</template>

<script>
//import HelloWorld from './components/HelloWorld.vue'
//import Banner from './components/Banner.vue'

import About from "./components/About.vue";
// import ManagementPanel from "./components/ManagementPanel.vue"

export default {
  name: "App",
  components: {
    
    About,
  },
};
</script>

<style scope>

@import "./assets/fonts/fonts.css";

#sorounding {

  background-color: rgb(239, 114, 2);
  margin: 0;
  align-content: center;
  justify-content: center;
  border: 0px;
  border-width: 0px;
  border-top-width: 0px;
  

}

#content {
  
  padding-top: 2%;
  padding-right: 5%;
  padding-left: 5%;
  width: 90%;
  
}

#app {
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  
}

#orange-bar {

  
  height: 30px;
  background-color: rgb(239, 114, 2);;

}


#banner {

  border-radius: 50px 50px 0px 0px;
  font-family: "Frutiger_65_Bold", sans-serif;
  background: white;
  

}


/* Elemente am oberen Ende der Landing Page */
#banner div div {
  color: black;
  display: inline-block;
  padding: 2%;
  text-transform: uppercase;
  font-size: 1.3em;
  font-weight: bold;
}

/* App Logo */
#icon {
  width: 7%;
}

#banner_image {

  width: 100%;
}

/* Dieses div ist noch zu klären */
#banner img {
  max-width: 100%; 
}


#banner div a div:hover {
  color: rgb(239, 114, 2);
  
  /* Horitzontal und vertikale Verschiebung des Schattens */
  text-shadow: 0.1px 0.1px  gray;
}

/*  
    ToDo: Highlighting
    Nochmal prüfen mit Vue.js

 */
#banner div a[href="#/"] {

}

@media screen and (min-width: 2200px) {
  
  #sorounding {
    display: block;
    
    

  }

  #content {
    
      text-align: center;
      width: 1900px;
      position: absolute;
      margin-left: auto; 
      margin-right: auto; 
      left: 0; 
      right: 0; 
      top: 5%;
      padding: 0;
      

  }


}

@media screen and (max-width: 1100px) {
  
  #content {
    
    padding-top: 5%;

  }


}

@media screen and (max-width: 800px) {

  #content {
    
    padding-top: 5%;


  }

}


</style>
