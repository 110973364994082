<template>
    <section>
    <div class="kurz"> 
						<p class="intro">KURZ -&nbsp; das ist die Marke für professionelle Abfallentsorgung in Baden-Württemberg und darüber hinaus</p>
						<p>Wir sind ein mittelständisches Entsorgungs- und Dienstleistungsunternehmen mit Sitz in Ludwigsburg und acht weiteren Standorten. Unser Kernmarkt ist das nördliche Baden-Württemberg mit den Wirtschaftsregionen Mittlerer Neckar, Heilbronn-Franken sowie Nordbaden rund um die Ballungszentren Stuttgart, Heilbronn und Karlsruhe. Ergänzend betreiben wir einen Container-Shop, der über die Grenzen von Baden-Württemberg hinaus aktiv ist und von Kunden bundesweit genutzt werden kann.&nbsp;</p>
						<p>In rund 50 Jahren haben wir uns ein umfassendes Know-How im Bereich der Abfallentsorgung angeeignet. Heute bieten wir einen vollständigen Service rund um die Entsorgung und weit darüber hinaus - aus einer Hand. Unser ausgebildetes Fachpersonal bietet nicht nur kompetente Beratung, sondern modernes Abfallmanagement bis hin zur kompletten Objektbetreuung.</p>
						<p>Zu unseren Kunden gehören Betriebe aus Gewerbe, Industrie, Dienstleistung und Handel genauso wie Institutionen und Privathaushalte. Und: Wir sorgen als Dienstleister für Landkreise und Kommunen Tag für Tag dafür, dass zigtausende Abfall- und Wertstofftonnen sowie öffentliche Papier-, Glas- und Dosencontainer geleert werden.</p>
						<p>Kleinste und größte Entsorgungsfragen sind unsere Aufgabe.</p>
						<p>Stellen Sie uns auf die Probe!</p>
     </div>
     </section>
</template>

<script>
export default {
    
}
</script>


<style scoped>

@import "../assets/fonts/fonts.css";


section {
  padding: 20px;
  margin: 0px;
  background-color: rgb(255, 255, 255);
  border-radius: 0px 0px 50px 50px;
}

.kurz {

  text-align: left;
  font-family: "Frutiger_65_Bold";
   margin-left: 2%;
}

.kurz .intro {
    font-family: "Frutiger_65_Bold";
    font-size: larger;
     color: rgb(239, 114, 2);
}

</style>