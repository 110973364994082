<template>

    <section>
        <h1>Die Seite wurde nicht gefunden</h1>

        <p>Navigieren Sie sich über die Navigationsleiste bitte wieder zurück.</p>
    </section>
    
</template>

<script>
export default {
    
}
</script>

<style scoped>

@import "../assets/fonts/fonts.css";
@import "../assets/defaultColors.css";
@import "../assets/defaultFonts.css";

h1 {
    color: var(--defaultColorsOrange);
    font-family: var(--defaultFontsFrutigerBold);
    font-size: 2em;
}

p {
    font-family: var(--defaultFontsFrutigerBold);
}


</style>