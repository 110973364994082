<template>


    <section>
        <div class="impressum">
        <h2>Impressum </h2>

            <p>KURZ Entsorgung GmbH<br /> Mühläckerstraße 26<br /> 71642 Ludwigsburg</p>

            <p>Tel.: 07144 8442-0<br />Fax: 07144 8442-77<br /><a type="email" href="mailto:info&#64;kurz-entsorgung.de"> E-Mail: info&#64;kurz-entsorgung.de</a> </p>

            <p>Sitz der Gesellschaft: Ludwigsburg<br />Geschäftsführer: Peter Kurz<br />Handelsregister: Amtsgericht Stuttgart (Stadt Stuttgart)<br />Handelsregisternr.: HRB 200868<br />USt.-ID: DE218 399 979</p>
        
            <p>Verantwortlich gemäß § 55 Abs. 2 RStV: Benedikt Kurz, Mühläckerstraße 26, 71642 Ludwigsburg.</p>
			<h3>Informationspflichten gem. § 36 VSBG, Streitbeilegungsplattform:</h3>
            <p>Wir nehmen nicht an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teil. Das Gesetz über die alternative Streitbeilegung in Verbrauchersachen (VSBG) fordert aber, dass wir Sie trotzdem auf eine für Sie zuständige Verbraucherschlichtungsstelle hinweisen:</p>
                        
            <p>Allgemeine Verbraucherschlichtungsstelle des Zentrums für Schlichtung e.V.<br />Straßburger Straße 8<br />77694 Kehl<br />
                            <a href="http://www.verbraucher-schlichter.de/" title="Öffnet internen Link im aktuellen Fenster" target="_blank" class="external-link-new-window">www.verbraucher-schlichter.de</a> </p>

            <p>Online-Streitbeilegung (Art. 14 Abs. 1 ODR-Verordnung): Die Europäische Kommission stellt unter&nbsp;<a href="http://ec.europa.eu/consumers/odr/" title="Öffnet internen Link im aktuellen Fenster" target="_blank" class="external-link-new-window">ec.europa.eu/consumers/odr/</a>&nbsp;eine Plattform zur Online-Streitbeilegung zur Verfügung.</p>

            <p>Die Maße der Modelle entsprechenden der gängigen Bauarten. Es kann allerdings immer noch zu Abweichungen kommen. Es besteht kein Anspruch auf die Genauigkeit der Angaben.</p>

            <p>Diese Website wurde mit größter Sorgfalt erstellt. Falls sich sonst doch noch Irrtümer in dieser befinden, bitte wir um Entschuldigung.</p>

            <p>Einer Nutzung der im Impressum angegebenen Daten zu Werbezwecken widersprechen wir ausdrücklich.</p>

            </div>
    </section>
    
</template>


<script>
export default {
    
}
</script>


<style scoped>

@import "../assets/fonts/fonts.css";

section {
    font-family: "Frutiger_65_Bold";
    text-align: left;
    
    background: white;
    padding: 2%;
    border-radius: 0px 0px 50px 50px;
}

h2 {
  text-transform:  uppercase;
}

h2, h3 {
  color: rgb(239, 114, 2);
}

/* Andere Anzeige der Links */
a {
    text-decoration: none;
    color: rgb(239, 114, 2);
    font-weight: bold;
}


</style>