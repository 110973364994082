<template>
  <section>
    <h2>Datenschutzerklärung</h2>

    <p>
      Der Schutz Ihrer Daten liegt uns am Herzen. Um diese Website zu besuchen,
      müssen Sie keine Daten eingeben.
    </p>

    <h3>Verantwortliche Stelle</h3>
    <p>Verantwortliche Stelle ist:</p>
    <p>KURZ Holding GmbH<br> Mühläckerstraße 26<br> 71642 Ludwigsburg</p>

    <h3>Gegenstand des Datenschutzes</h3>
    <p>
      Gegenstand des Datenschutzes sind personenbezogene Daten. Diese sind nach
      § 3 Abs. 1 BDSG Einzelangaben über persönliche oder sachliche Verhältnisse
      einer bestimmten oder bestimmbaren natürlichen Person. Hierunter fallen z.
      B. Angaben wie Name, Post-Adresse, E-Mail-Adresse oder Telefonnummer, ggf.
      aber auch Nutzungsdaten wie Ihre IP-Adresse.
    </p>

    <h3>Datenschutzbeauftragter</h3>
    <p>Barth Datenschutz GmbH<br>
    Brunnengasse 3<br>
    73650 Winterbach</p>
    <p>Achim Barth<br>
    <a href="mailto:barth@barth-datenschutz.de">barth@barth-datenschutz.de</a></p>

    <h3>Gespeicherte Daten, zweckgebundene Datenverwendung, Nutzungszweck</h3>
    <p>
      Wir speichern zum Zweck der Systemsicherheit lediglich anonymisierte
      Zugriffsdaten ohne Personenbezug, wie Namen Ihres Internet Service
      Providers, Erkennungsdaten des verwendeten Browser- und
      Betriebssystems-Typs, die Seite, von der aus Sie uns besuchen und welche
      Seiten Sie besuchen sowie den Namen der angeforderten Datei, Datum und
      Uhrzeit des Abrufs und die Information, ob der Abruf erfolgreich war.
    </p>
    <p>
      Die Auswertung dient allein der statistischen Aufbereitung und
      Verbesserung unseres Internet-Angebots. Eine dauerhafte Speicherung ist
      ausgeschlossen. Sie können dieser Datenerhebung, -speicherung und
      -verarbeitung jederzeit widersprechen.
    </p>
    <p>
      Die von Ihnen zur Verfügung gestellten personenbezogenen Daten verwenden
      wir ausschließlich zum Zweck der technischen Administration der Webseiten
      und zur Erfüllung der Wünsche und Anforderungen der Nutzer oder zur
      Beantwortung einer Anfrage.
    </p>

    <h3>Formular zur Dateneingabe</h3>
    <p>
      Wenn Sie uns per Datenformular Anfragen, insbesondere hinsichtlich
      Entsorgungs-Angeboten, zukommen lassen, werden Ihre Angaben aus dem
      Anfrageformular inklusive der von Ihnen dort angegebenen Kontaktdaten
      zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen bei
      uns gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung
      weiter.
    </p>

    <h3>Weitergabe von Daten</h3>
    <p>
      Wir geben Ihre personenbezogenen Daten nicht an Dritte weiter. Ausgenommen
      hiervon sind lediglich unsere Dienstleistungspartner, die wir zur
      Abwicklung des Vertragsverhältnisses benötigen. In diesen Fällen beachten
      wir die Vorgaben des Bundesdatenschutzgesetzes strikt; der Umfang der
      Datenübermittlung beschränkt sich auf ein Mindestmaß.
    </p>

    <h3>
      Erhebung und Speicherung von Nutzungsdaten, Umfang der Datenerhebung und
      -speicherung
    </h3>
    <p>
      Wir verwenden die Daten ausschließlich im Zusammenhang mit dem Betrieb der
      Website&nbsp;<a
        href="http://www.kurz-entsorgung.de/"
        title="Öffnet externen Link in neuem Fenster"
        target="_blank"
        class="external-link-new-window"
        >www.kurz-entsorgung.de</a
      >. Wir sind berechtigt, die genannten Daten zu den genannten Zwecken zu
      erheben, zu verarbeiten, zu speichern sowie zu eigenen Zwecken zu nutzen.
      Der Nutzer erteilt hierzu ausdrücklich seine Zustimmung. Wir sind
      ebenfalls berechtigt, im Zusammenhang mit Anfragen mit Nutzern per E-Mail
      in Kontakt zu treten; dem stimmt der Nutzer zu.
    </p>
    <p>
      Nach Ablauf der steuer- und handelsrechtlichen Vorschriften werden die
      Daten gelöscht, soweit Sie nicht einer darüber hinaus gehenden
      Datenverwendung zugestimmt haben.
    </p>

    <h3>Datensicherheit</h3>
    <p>
      Die Datenübertragung im Internet (auch die Kommunikation per E-Mail) hat
      Sicherheitslücken.
    </p>
    <p>
      Wir treffen alle notwendigen technischen und organisatorischen
      Sicherheitsmaßnahmen, um personenbezogene Daten möglichst vor Verlust und
      Missbrauch zu schützen. So werden Daten in einer sicheren Betriebsumgebung
      gespeichert, die der Öffentlichkeit nicht zugänglich ist. Vertrauliche
      Informationen werden, sofern möglich, per SSL-Zertifikat verschlüsselt
      über das HTTPS-Protokoll übertragen.
    </p>

        <!--
    <h3>Cookies</h3>
    <p>
      Zur Datenerhebung und Speicherung setzen wir Cookies ein. Cookies sind
      kleine Dateien, die Ihr Browser auf unsere Veranlassung in Ihrem Endgerät
      speichert. Dort richten sie keinen Schaden an. Sie enthalten keine Viren
      und erlauben uns nicht, Sie auszuspähen. Verwendung finden dabei
      ausschließlich temporäre Cookies. Auch diese Daten erheben wir nur
      anonymisiert. Diese Session-Cookies werden nach Verlassen der Seite
      gelöscht und dienen nur dazu, die Seite an Ihre Bedürfnisse anzupassen
      sowie Ihre Anmeldung während Ihres Besuchs zu speichern und den von Ihnen
      besuchten Seiten zuzuordnen. Sollten Sie die Speicherung von Cookies nicht
      wünschen, können Sie dies in Ihren Browsereinstellungen entsprechend
      vermerken. Hierzu bei Benutzung von Firefox unter Einstellung --&gt;
      Einstellung --&gt; Datenschutz --&gt; Häkchen bei &quot;Cookies
      akzeptieren&quot; herausnehmen, beim Explorer finden Sie diese unter
      Ansicht --&gt; Internetoptionen --&gt; Erweitert --&gt; Cookies. In diesem
      Fall kann es sein, dass Sie nicht alle Funktionen der Seite nutzen können.
    </p>
        
    <h3>Nutzung Google Analytics</h3>
    <p>
      Diese Website benutzt Google Analytics, einen Webanalysedienst der Google
      Inc. ("Google"). Google Analytics verwendet sog. "Cookies", Textdateien,
      die auf Ihrem Computer gespeichert werden und die eine Analyse der
      Benutzung der Website durch Sie ermöglichen. Die durch den Cookie
      erzeugten Informationen über Ihre Benutzung dieser Website werden in der
      Regel an einen Server von Google in den USA übertragen und dort
      gespeichert. Wir nutzen die Funktion "Aktivierung der IP-Anonymisierung"
      auf dieser Webseite. Dadurch wird die IP-Adresse von Google innerhalb von
      Mitgliedsstaaten der Europäischen Union oder in anderen Vertragsstaaten
      des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt, so dass
      keine Zuordnung zu Ihrem Rechner mehr möglich ist. Nur in Ausnahmefällen
      wird die volle IP-Adresse an einen Server von Google in den USA übertragen
      und dort gekürzt.e in diesem Fall gegebenenfalls nicht sämtliche
      Funktionen dieser Website vollumfänglich werden nutzen können. Sie können
      darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre
      Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google
      sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie das
      unter dem folgenden Link verfügbare Browser-Plugin herunterladen und
      installieren.
    </p>
    <p>
      In unserem Auftrag wird Google diese Informationen benutzen, um Ihre
      Nutzung der Website auszuwerten, Reports über die Websiteaktivitäten
      zusammenstellen und um weitere mit der Websitenutzung und der
      Internetnutzung verbundene Leistungen für uns zu erbringen. Die mittels
      Google Analytics übermittelte IP-Adresse wird von Google nicht mit anderen
      Daten zusammengeführt. Wir haben mit Google einen Vertrag zur
      Auftragsdatenverarbeitung abgeschlossen, um eine datenschutzgerechte
      Nutzung von Google Analytics zu ermöglichen.
    </p>
    <p>
      <a
        href="http://tools.google.com/dlpage/gaoptout?hl=de."
        title="Öffnet internen Link im aktuellen Fenster"
        target="_blank"
        >http://tools.google.com/dlpage/gaoptout?hl=de.</a
      >
    </p>
    <p>
      Sie können die Erfassung durch Google Analytics auch verhindern, indem Sie
      auf folgenden Link klicken. Es wird ein Opt-Out-Cookie gesetzt, das die
      zukünftige Erfassung Ihrer Daten beim Besuch dieser Website
      verhindert:&nbsp;<a>Google Analytics deaktivieren</a>
    </p>
-->
    <h3>Auskunft- und Widerrufsrecht</h3>
    <p>
      Sie erhalten jederzeit ohne Angabe von Gründen kostenfreie Auskunft über
      Ihre bei uns gespeicherten Daten. Sie können jederzeit Ihre bei uns
      erhobenen Daten sperren, berichtigen oder löschen lassen. Sie können
      jederzeit die uns erteilte Einwilligung zur Datenerhebung, Verarbeitung
      und Verwendung ohne Angabe von Gründen widerrufen. Wenden Sie sich hierzu
      an unseren&nbsp;<a
        href="mailto:barth@barth-datenschutz.de"
        title="Öffnet internen Link im aktuellen Fenster"
        class="mail"
        >Datenschutzbeauftragten</a
      >. Nach einem Widerruf können Sie die Webseite eventuell nicht in vollem
      Umfang nutzen.
    </p>
  </section>
</template>


<script>
export default {};
</script>


<style scoped>
@import "../assets/fonts/fonts.css";

main {
  
}

section {
  font-family: "Frutiger_65_Bold";
  text-align: left;
  background: white;
  padding: 2%;
  border-radius: 0px 0px 50px 50px;
  
}

h2 {
  text-transform:  uppercase;
}

h2, h3 {
  color: rgb(239, 114, 2);;
}

main .impressum {
  justify-content: left;
  align-items: left;
}

main .impressum .intro {
  font-size: 1.5em;
}

/* Andere Anzeige der Links */
a {
    text-decoration: none;
    color: rgb(239, 114, 2);
    font-weight: bold;
}
</style>